<template>
  <svg viewBox="0 0 20 21">
    <path
      d="M10.8333 18V9.66667H17.5V18H10.8333ZM2.5 11.3333V3H9.16667V11.3333H2.5ZM7.5 9.66667V4.66667H4.16667V9.66667H7.5ZM2.5 18V13H9.16667V18H2.5ZM4.16667 16.3333H7.5V14.6667H4.16667V16.3333ZM12.5 16.3333H15.8333V11.3333H12.5V16.3333ZM10.8333 3H17.5V8H10.8333V3ZM12.5 4.66667V6.33333H15.8333V4.66667H12.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DashboardIcon",
};
</script>
